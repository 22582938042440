import { Component, Host, h, Prop, State, Element, forceUpdate } from '@stencil/core';
import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';
import localeRu from 'air-datepicker/locale/ru';

const pluginLocale = {
  en: localeEn,
  ru: localeRu,
};

const componentLocale = {
  en: {
    yesterday: 'Yesterday',
    today: 'Today',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    time: 'Time selection',
  },
  ru: {
    yesterday: 'Вчера',
    today: 'Сегодня',
    week: 'Неделя',
    month: 'Месяц',
    year: 'Год',
    time: 'Выбор времени',
  },
};

@Component({
  tag: 'x-daterangepicker',
  styleUrl: 'x-daterangepicker.sass',
})
export class XDaterangepicker {
  boundaryRef!: HTMLDivElement;
  inputDateFrom;
  inputDateTo;
  valueInput;
  datepicker;
  tooltip;

  @Element() el: HTMLElement;

  @Prop() from: string;
  @Prop() to: string;
  @Prop() button: string;

  @State() currentView: string = 'days';
  @State() value: any = {};

  private parseValue = () => {
    this.value = ['from', 'to'].reduce((result, key) => {
      const [date, time] = this[key].split(' ');
      const [day, month, year] = date.split('.').map(v => parseInt(v, 10));
      const [hours, minutes, seconds] = time.split(':').map(v => parseInt(v, 10));

      result[key] = {
        date: [day, month, year],
        time: [hours, minutes, seconds],
      };

      return result;
    }, {});
  };

  private handleChangeDate = () => {
    const fromValue = this.inputDateFrom.querySelector('input').value.split('.').reverse().join('.');
    const toValue = this.inputDateTo.querySelector('input').value.split('.').reverse().join('.');

    this.datepicker.selectDate([fromValue, toValue]);
  };

  private apply = e => {
    Object.keys(e.detail).forEach(type => {
      this.value[type].time = e.detail[type];
    });

    this.valueInput.dispatchEvent(new Event('change', { bubbles: true }));
    this.tooltip.hide();

    forceUpdate(this.el);
  };

  private renderValue = (key, type, delimiter) => {
    return this.value[key][type].map((v, i) => (type === 'date' && i === 2 ? v : `0${v}`.slice(-2))).join(delimiter);
  };

  connectedCallback() {
    this.parseValue();
  }

  componentDidLoad() {
    this.datepicker = new AirDatepicker(this.boundaryRef, {
      inline: true,
      range: true,
      keyboardNav: false,
      locale: pluginLocale[document.documentElement.lang || 'en'],
      prevHtml: '<svg><path d="M3.41658 7.41164L8.00488 12.0006L9.4191 10.5864L4.83161 5.99825L9.42096 1.41421L8.00675 0L2 5.99979L3.41421 7.41401L3.41658 7.41164Z" /></svg>',
      nextHtml:
        '<svg><path d="M8.58934 4.58899L4.00104 0L2.58682 1.41421L7.17431 6.00239L2.58496 10.5864L3.99917 12.0006L10.0059 6.00084L8.5917 4.58663L8.58934 4.58899Z" /></svg>',
      navTitles: {
        days: 'MMMM yyyy',
      },
      maxDate: new Date(),
      onChangeView: view => {
        this.currentView = view;
      },
      onSelect: params => {
        const dates = params.date as Date[];

        if (dates.length === 2) {
          ['from', 'to'].forEach((key, index) => {
            const date = dates[index];

            this.value[key].date = [date.getDate(), date.getMonth() + 1, date.getFullYear()];
          });

          forceUpdate(this.el);
        }
      },
    });
  }

  private renderInputValue = () => {
    return `${this.renderValue('from', 'date', '.')} ${this.renderValue('from', 'time', ':')} - ${this.renderValue('to', 'date', '.')} ${this.renderValue('to', 'time', ':')}`;
  };

  private applyPreset = e => {
    e.stopPropagation();

    let dateStart = new Date();
    let dateEnd = new Date();

    switch (e.target.value) {
      case 'yesterday':
        dateStart.setDate(dateStart.getDate() - 1);
        dateEnd.setDate(dateEnd.getDate() - 1);
        break;
      case 'week':
        dateStart.setDate(dateStart.getDate() - dateStart.getDay() + (dateStart.getDay() === 0 ? -6 : 1));
        dateEnd = new Date(dateStart);
        dateEnd.setDate(dateStart.getDate() + 6);
        break;
      case 'month':
        dateStart = new Date(dateStart.getFullYear(), dateStart.getMonth(), 1);
        dateEnd = new Date(dateEnd.getFullYear(), dateEnd.getMonth() + 1, 0);
        break;
      case 'year':
        dateStart = new Date(dateStart.getFullYear(), 0, 1);
        dateEnd = new Date(dateEnd.getFullYear(), 11, 31);
        break;
      default:
        break;
    }

    if (this.datepicker) {
      this.datepicker.selectDate([dateStart, dateEnd]);
    }

    setTimeout(() => {
      this.valueInput.value = this.renderInputValue();
      this.valueInput.dispatchEvent(new Event('change', { bubbles: true }));
    });
  };

  render() {
    const lang = document.documentElement.lang || 'en';

    return (
      <Host class="x-daterangepicker">
        <input type="hidden" name="daterange" value={this.renderInputValue()} ref={el => (this.valueInput = el)} />
        <div class="x-daterangepicker__presets">
          <x-toggle-button group exclusive size="sm" name="preset" onChange={this.applyPreset}>
            <x-grid container gap="4xs" nowrap>
              <x-grid item size="auto">
                <x-toggle-button value="yesterday">{componentLocale[lang].yesterday}</x-toggle-button>
              </x-grid>
              <x-grid item size="auto">
                <x-toggle-button value="today" selected>
                  {componentLocale[lang].today}
                </x-toggle-button>
              </x-grid>
              <x-grid item size="auto">
                <x-toggle-button value="week">{componentLocale[lang].week}</x-toggle-button>
              </x-grid>
              <x-grid item size="auto">
                <x-toggle-button value="month">{componentLocale[lang].month}</x-toggle-button>
              </x-grid>
              <x-grid item size="auto">
                <x-toggle-button value="year">{componentLocale[lang].year}</x-toggle-button>
              </x-grid>
            </x-grid>
          </x-toggle-button>
        </div>
        <x-tooltip trigger="click" theme="ghost" placement="bottom-end" offset="16,0" max-width="312" flip={false} interactive instance={instance => (this.tooltip = instance)}>
          <button type="button" class="x-daterangepicker__selected">
            <span class="x-daterangepicker__selected-entry">
              {this.renderValue('from', 'date', '.')}&nbsp;&nbsp;{this.renderValue('from', 'time', ':')}
            </span>
            <span class="x-daterangepicker__selected-icon">
              <x-icon glyph="calendar-range" size="8"></x-icon>
            </span>
            <span class="x-daterangepicker__selected-divider">—</span>
            <span class="x-daterangepicker__selected-entry">
              {this.renderValue('to', 'date', '.')}&nbsp;&nbsp;{this.renderValue('to', 'time', ':')}
            </span>
            <span class="x-daterangepicker__selected-icon">
              <x-icon glyph="calendar-range" size="8"></x-icon>
            </span>
          </button>
          <x-tooltip-content>
            <div class="x-daterangepicker__dropdown">
              <div class="x-daterangepicker__range -type-date">
                <x-input mask="date" value={this.renderValue('from', 'date', '.')} onChange={this.handleChangeDate} ref={el => (this.inputDateFrom = el)}></x-input>
                <div class="x-daterangepicker__range-divider"></div>
                <x-input mask="date" value={this.renderValue('to', 'date', '.')} onChange={this.handleChangeDate} ref={el => (this.inputDateTo = el)}></x-input>
              </div>
              <div class="x-daterangepicker__boundary" ref={el => (this.boundaryRef = el as HTMLDivElement)}></div>
              {this.currentView === 'days' && (
                <div class="x-daterangepicker__timepicker">
                  <h4>{componentLocale[lang].time}</h4>
                  <x-timepicker from={this.renderValue('from', 'time', ':')} to={this.renderValue('to', 'time', ':')} onUpdate={this.apply} button={this.button}></x-timepicker>
                </div>
              )}
            </div>
          </x-tooltip-content>
        </x-tooltip>
      </Host>
    );
  }
}
