.x-daterangepicker
  display: flex
  align-items: center
  position: relative
  &__presets
    margin-right: 16px
  &__selected
    display: flex
    align-items: center
    white-space: nowrap
    color: var(--on-surface-dark)
    border: none
    background: none
    height: 40px
    &-entry
      +preset(5)
    &-divider
      +preset(5)
      margin: 0 6px
    &-icon
      margin-left: 6px
      color: var(--icon-primary)
  &__dropdown
    // position: absolute
    top: 100%
    right: -16px
    background-color: var(--surface-white)
    box-shadow: var(--surface-shadow)
    border-radius: 6px
    padding: 16px 16px 0
    z-index: 10
  &__boundary
    user-select: none
    padding-bottom: 16px
  &__range
    display: flex
    align-items: center
    &-divider
      width: 4px
      height: 1px
      margin: 0 4px
      flex: 0 0 auto
      background-color: var(--divider-dark-grey)
    &.-type-date
      position: absolute
      top: 64px
      left: 16px
      right: 16px
  &__timepicker
    border-top: 1px solid var(--divider-dark-grey)
    padding-bottom: 16px
    h4
      text-align: center
      margin: 16px 0

.air-datepicker
  width: 280px

.air-datepicker-nav
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 65px
  &--action
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    width: 32px
    height: 32px
    border-radius: 4px
    box-sizing: border-box
    color: var(--button-static-dark-grey)
    border: 1px solid currentColor
    svg
      width: 12px
      height: 12px
      fill: currentColor
    &:hover
      color: var(--button-active-primary)
    &.-disabled-
      background-color: var(--button-disabled)
      border-color: var(--button-disabled)
      color: var(--on-button-white)
      cursor: not-allowed
  &--title
    +preset(1)
    white-space: nowrap
    color: var(--on-button-dark)
    cursor: pointer
    &:hover
      color: var(--on-button-hover-primary)

.air-datepicker-body
  &--day-names
    +preset(3)
    display: grid
    text-align: center
  &--cells
    +preset(2)
    display: grid
  &--day-names,
  &--cells.-days-
    grid-template-columns: repeat(7, 40px)
    grid-row-gap: 1px
  &--day-name
    height: 40px
    line-height: 40px
    color: var(--on-surface-dark-50)
    &.-weekend-
      color: var(--on-surface-red)
  &--cells.-months-,
  &--cells.-years-
    grid-template-columns: repeat(3, 92px)
    grid-gap: 2px
  &.-hidden-
    display: none

.air-datepicker-cell
  cursor: pointer
  position: relative
  text-align: center
  color: var(--on-button-dark)
  &.-month-,
  &.-year-
    height: 92px
    line-height: 92px
    &.-current-:after
      bottom: 7px
  &.-day-
    height: 40px
    line-height: 40px
    &.-in-range-
      background-color: var(--button-active-light-grey)
    &.-range-from-,
    &.-range-to-
      // background-color: var(--button-static-primary) 
      color: var(--on-button-white)
      &.-current-:after
        background-color: currentColor
    &.-range-from-
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H27.5279C29.043 0 30.428 0.85601 31.1056 2.21115L39.1056 18.2111C39.6686 19.3373 39.6686 20.6627 39.1056 21.7889L31.1056 37.7889C30.428 39.144 29.043 40 27.5279 40H0V0Z' fill='%230091D9'/%3E%3C/svg%3E%0A")
    &.-range-to-
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.89443 2.21115C9.57199 0.856011 10.957 0 12.4721 0H40V40H12.4721C10.957 40 9.572 39.144 8.89443 37.7889L0.894428 21.7889C0.331373 20.6627 0.331372 19.3373 0.894427 18.2111L8.89443 2.21115Z' fill='%230091D9'/%3E%3C/svg%3E%0A")
    // &.-range-from-.-range-to-
    //   background-color: var(--button-static-primary)
    //   background-image: none
    &.-current-:after
      top: 50%
      margin-top: 10px
  &.-selected-
    background-color: var(--button-active-light-grey)
    color: var(--on-button-active-primary)
  &.-current-
    &:after
      content: ""
      width: 6px
      height: 2px
      background-color: var(--on-button-primary)
      display: block
      position: absolute
      left: 50%
      margin-left: -3px
  &.-other-month-
    color: var(--on-button-dark-grey)
  &.-focus-
    background-color: var(--button-hover-light-grey)
  &.-disabled-
    cursor: not-allowed
    background: none
    opacity: 0.25
